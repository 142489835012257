import React from "react";

function SecondaryPublicLayout(props) {
	return (
		<div>
		yest
		</div>
	);
}

export default SecondaryPublicLayout;
