import React, {useState} from "react";
import { connect } from "react-redux";
import {
  Card,
  Button,
  CustomDialog,
  Searchbar,
  Text,
  TextBox,
} from "../../../../../../components";
import { register } from "../../../../../../store/actions";

const Signup = (props) => {
  const [registerInput, setRegisterInput] = useState({});

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterInput({ ...registerInput, [name]: [value] });
  };

  return (
    <div>
      <div className="py-4">
        <div className="py-4">
          <Text variant="black" size="lg">
            Register
          </Text>
        </div>
        <div className="space-y-4">
          <div>
            <TextBox
              type="text"
              placeHolder="full name"
              label="Name"
              onChange={handleRegisterChange}
              value={registerInput.name}
              name="name"
              orientation="vertical"
            />
          </div>
          <div>
            <TextBox
              type="email"
              name="email"
              onChange={handleRegisterChange}
              value={registerInput.email}
              placeHolder="user@gmail.com"
              label="email"
              orientation="vertical"
            />
          </div>
          <div>
            <TextBox
              type="number"
              onChange={handleRegisterChange}
              value={registerInput.mobileNumber}
              placeHolder="+91554477885"
              label="Mobile Number"
              name="mobileNumber"
              orientation="vertical"
            />
          </div>
          <div>
            <TextBox
              type="password"
              onChange={handleRegisterChange}
              value={registerInput.password}
              name="password"
              placeHolder="*******"
              label="Password"
              orientation="vertical"
            />
          </div>
          <div>
            <div>
              <Text variant="primary">Nft Address</Text>
            </div>
            <Text variant="primary" size="xs">
              {props.eth}
            </Text>
          </div>
          <div>
            <Button
              color="primary"
              variant="primary200"
              onClick={() => props.register(registerInput)}
            >
              Register
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, register)(Signup);
