import React from "react";

const Bids = () => {
  return (
    <div>
      <div className="h-80 custom-scroll overflow-y-scroll">
        <div className="p-4 text-justify">Bids sa ad</div>
      </div>
    </div>
  );
};

export default Bids;
