import React from "react";
import SideDrawer from "@material-ui/core/Drawer";
import { COLORS } from "../../../../../../theme/mainTheme";
import { Text } from "../../../../../../components";
import { history } from "../../../../../../utils";

const list = (close) => {
  const handleRoute = (route) => {
    history.push(route);
    close();
  };

  return (
    <div
      className="p-8 space-y-6"
      style={{
        width: "15rem",
        backgroundColor: "#f1f1f1",
        height: "100%",
      }}
    >
      <div onClick={() => handleRoute("/")}>
        <Text>Marketplace</Text>
      </div>
      <div onClick={() => handleRoute("/stores")}>
        <Text>Stores</Text>
      </div>
      <div onClick={() => handleRoute("/my-collection")}>
        <Text>My collection</Text>
      </div>
      <div onClick={() => handleRoute("/create")}>
        <Text>Create</Text>
      </div>
    </div>
  );
};

const Drawer = ({ isOpen, close }) => {
  return (
    <div>
      <SideDrawer anchor="right" open={isOpen} onClose={close}>
        {list(close)}
        <div>
          <span class="flex justify-between p-4 bg-blue-100">
            <a class="text-gray-500">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="ml-3 text-gray-500">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a class="ml-3 text-gray-500">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a class="ml-3 text-gray-500">
              <svg
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </span>
        </div>
      </SideDrawer>
    </div>
  );
};

export default Drawer;
