import React, { useState } from "react";
import {
  CustomDialog,
  ImageField,
  Text,
  Button,
  TextBox,
} from "../../components";
import { Products } from "./components";

const Profile = () => {
  return (
    <div className="py-10">
      <div className="space-y-4">
        <div className="text-center ">
          <Text>Welcome</Text>
        </div>
        <div className="md:w-60 mx-auto">
          <ImageField label="Select Profile Photo" />
        </div>
      </div>

      <div className="py-6 space-y-4">
        <div>
          <TextBox
            label="Display Name"
            orientation="vertical"
            placeHolder="Enter your display name"
          />
          <span>
            <Text size="xs" variant="primary">
              Only use letters, numbers, underscores and emojis
            </Text>
          </span>
        </div>
        <div>
          <TextBox
            label="Bio"
            orientation="vertical"
            placeHolder="Tell us a little about yourself"
          />
          <span>
            <Text size="xs" variant="primary">
              URLs are allowed
            </Text>
          </span>
        </div>
      </div>

      <div>
        <Button variant="primary200" color="primary">
          Next
        </Button>
      </div>
    </div>
  );
};

const Home = () => {
  const [profileDialogIsOpen, setProfileDialogIsOpen] = useState(false);

  return (
    <div>
      <CustomDialog
        isOpen={profileDialogIsOpen}
        DialogComponent={Profile}
        setOpen={(val) => setProfileDialogIsOpen(val)}
      />
      <div
        className="flex items-center justify-center"
        style={{ height: `calc(100vh - 5rem)` }}
      >
        <img src="/static/images/banne.jpg" />
      </div>

      <div className="py-10">
        <Products />
      </div>
    </div>
  );
};

export default Home;
