import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  Button,
  CustomDialog,
  Searchbar,
  Text,
  TextBox,
} from "../../../../components";
import { Drawer, SearchSlide } from "./components";
import { register, login } from "../../../../store/actions";
import { history } from "../../../../utils";

const Navbar = (props) => {
  const [drawer, setDrawer] = useState(false);
  const [searchSlide, setSearchSlide] = useState(false);
  const [eth, setEth] = useState(null);
  const [signupDialogIsOpen, setSignupDialogIsOpen] = useState(false);
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false);

  const handleDrawer = () => {
    setDrawer(true);
  };

  const handleSearchSlide = () => {
    setSearchSlide(true);
  };

  const drawerClose = () => {
    setDrawer(false);
  };

  const sliderClose = () => {
    setSearchSlide(false);
  };

  const handleMeataMaskClick = async () => {
    const ethereum = window.ethereum;

    if (ethereum) await ethereum.enable();

    setEth(window.ethereum.selectedAddress);

    ethereum.on("accountsChanged", (accounts) => {
      setEth(accounts[0]);
    });
  };

  const handleRoute = (route) => {
    history.push(route);
  };

  const handleSignupClick = () => {
    setSignupDialogIsOpen(true);
    handleMeataMaskClick();
  };

  const handleLoginClick = () => {
    setLoginDialogIsOpen(true);
    handleMeataMaskClick();
  };

  const LoginDialogComponent = () => {
    const [input, setInput] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInput({ ...input, [name]: value });
    };

    return (
      <div className="py-4">
        <div className="py-4">
          <Text variant="primary">Login</Text>
        </div>
        <div className="space-y-4">
          <div>
            <TextBox
              type="email"
              placeHolder="Email"
              label="email"
              onChange={handleInputChange}
              value={input.email}
              name="email"
              orientation="vertical"
            />
          </div>
          <div>
            <TextBox
              type="password"
              placeHolder="password"
              label="password"
              onChange={handleInputChange}
              value={input.password}
              name="password"
              orientation="vertical"
            />
          </div>
          <div>
            <Button
              color="primary"
              variant="primary200"
              onClick={() => {
                props.login(input);
                setLoginDialogIsOpen(false);
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const SignupDialogComponent = () => {
    const [input, setInput] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInput({ ...input, [name]: value });
    };

    return (
      <div className="py-4">
        <React.Fragment>
          <div className="py-4">
            <Text variant="black" size="lg">
              Register
            </Text>
          </div>
          <div className="space-y-4">
            <div>
              <TextBox
                type="text"
                placeHolder="full name"
                label="Name"
                onChange={handleInputChange}
                value={input.name}
                name="name"
                orientation="vertical"
              />
            </div>
            <div>
              <TextBox
                type="email"
                name="email"
                onChange={handleInputChange}
                value={input.email}
                placeHolder="user@gmail.com"
                label="email"
                orientation="vertical"
              />
            </div>
            <div>
              <TextBox
                type="number"
                onChange={handleInputChange}
                value={input.mobileNumber}
                placeHolder="+91554477885"
                label="Mobile Number"
                name="mobileNumber"
                orientation="vertical"
              />
            </div>
            <div>
              <TextBox
                type="password"
                onChange={handleInputChange}
                value={input.password}
                name="password"
                placeHolder="*******"
                label="Password"
                orientation="vertical"
              />
            </div>
            <div>
              <div>
                <Text variant="primary">Nft Address</Text>
              </div>
              <Text variant="primary" size="xs">
                {eth}
              </Text>
            </div>
            <div>
              <Button
                color="primary"
                variant="primary200"
                isDisable={props.registerIsLoading ? true : false}
                onClick={() => {
                  props.register({ ...input, address: eth });

                  // close the dialog after successful register
                  if (props.registerMessage !== null) {
                    setSignupDialogIsOpen(false);
                  }
                }}
              >
                Register
              </Button>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  };

  return (
    <div className="fixed w-full">
      <Drawer isOpen={drawer} close={drawerClose} />
      <CustomDialog
        isOpen={signupDialogIsOpen}
        size="xs"
        fullWidth={true}
        DialogComponent={SignupDialogComponent}
        setOpen={(val) => setSignupDialogIsOpen(val)}
      />
      <CustomDialog
        isOpen={loginDialogIsOpen}
        size="xs"
        fullWidth={true}
        DialogComponent={LoginDialogComponent}
        setOpen={(val) => setLoginDialogIsOpen(val)}
      />
      <SearchSlide isOpen={searchSlide} close={sliderClose} />
      <nav className="bg-gray-900 py-6 md:block hidden">
        <div className="flex justify-between items-center px-12">
          <div>
            <img src="/static/images/mainlogo.png" width="80" />
            <img src="/static/images/logo.png" width="120" />
          </div>
          <div>
            <Searchbar placeholder="Search..." />
          </div>
          <ul className="flex space-x-6">
            <li onClick={() => handleRoute("/")} className="cursor-pointer">
              <Text variant="white">Marketplace</Text>
            </li>
            <li
              onClick={() => handleRoute("/stores")}
              className="cursor-pointer"
            >
              <Text variant="white">Stores</Text>
            </li>
            <li
              onClick={() => handleRoute("/my-collection")}
              className="cursor-pointer"
            >
              <Text variant="white">My Collection</Text>
            </li>
            <li
              onClick={() => handleRoute("/create")}
              className="cursor-pointer"
            >
              <Text variant="white">Create</Text>
            </li>
          </ul>

          {!props.isAuth ? (
            <div className="flex space-x-4">
              <div>
                <Button
                  color="primary"
                  rounded="full"
                  variant="primary200"
                  onClick={handleSignupClick}
                >
                  Signup
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  rounded="full"
                  variant="primary200"
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Button
                color="primary"
                rounded="full"
                variant="primary200"
                onClick={() => {
                  localStorage.removeItem("jwtToken");
                  window.location.reload();
                }}
              >
                Logout
              </Button>
            </div>
          )}
        </div>
      </nav>

      <nav className="gradient-bg py-6 md:hidden block ">
        <div className="flex justify-between px-4">
          <div>
            <Text variant="white">LOGO</Text>
          </div>
          <div className="flex space-x-6">
            <div onClick={handleSearchSlide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <div onClick={handleDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  registerIsLoading: auth.registerIsLoading,
  registerMessage: auth.registerMessage,
  isAuth: auth.isAuth,
});

export default connect(mapStateToProps, { register, login })(Navbar);
