import React from "react";

const Information = () => {
  return (
    <div>
      <div className="h-80 custom-scroll overflow-y-scroll">
        <div className="p-4 text-justify">information</div>
      </div>
    </div>
  );
};

export default Information;
